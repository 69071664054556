/* Layout.css */

/* Sidebar Styling */
.sidebar {
  background-color: #000;
  color: #fff;
  width: 20%; 
  height: 100vh; /* Full height */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  transition: width 0.3s ease;
}

.sidebar.collapsed {
  width: 80px; /* Collapsed width for small screens */
}
.sidebar .brand{
  align-items: center;
}
.sidebar .brand,
.sidebar .user-info {
  text-align: center;
}

.brand-logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.pdf-logo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.nav {
  margin-top: 30px;
  overflow: hidden;
}

.nav-link {
  color: #fff;
  padding: 10px 15px;
  text-decoration: none;
  display: block;
}

.nav-link:hover {
  background-color: #C0A16B;
  color: #000;
}

/* Toggle Button Styling */
.toggle-btn {
  position: absolute;
  top: 15px;
  right: -30px;
  background-color: #C0A16B;
  border: none;
  color: #000;
  padding: 10px;
  cursor: pointer;
  z-index: 1000;
  border-radius: 5px;
}

.toggle-btn:hover {
  background-color: #fff;
}

/* Main Content */
.main-content {
  margin-left: 20%;
  padding: 20px;
  width: 75%;
  transition: margin-left 0.3s ease;
}

.sidebar.collapsed + .main-content {
  margin-left: 70px; /* Adjust main content margin when sidebar is collapsed */
}

.text-gold {
  color: #C0A16B; /* Gold color from the logo */
}

.text-black {
  color: #000;
}

.text-gold {
  color: #c5a46d;
  text-align: center;
  margin-bottom: 2rem;
}

.btn-outline-gold {
  border-color: #c5a46d;
  color: white;
}

.btn-outline-gold:hover {
  background-color: #c5a46d;
  color: #fff;
}
.card {
  border-radius: 10px;
}

h3 {
  font-size: 1.5rem;
  font-weight: 600;
}
.brand-name{
  text-transform: uppercase;
  font-weight: bold;
  padding-left: 0.5em;
}
.register-admin {
  background-color: white;
  border: 1px solid #000;
  padding: 2rem;
  border-radius: 10px;
}

h2 {
  color: black;
}

.form-label {
  color: black;
}

.btn-gold {
  background-color: gold;
  color: black;
  border: none;
}

.btn-gold:hover {
  background-color: darkgoldenrod;
  color: white;
}

.alert-danger {
  background-color: #f8d7da;
  color: #721c24;
  border-color: #f5c6cb;
}

@media (max-width: 768px) {
  .card {
    margin-bottom: 20px;
  }
  .sidebar {
    width: 70px; /* Sidebar collapses on small screens */
  }

  .main-content {
    margin-left: 70px;
  }

  .sidebar .brand-name,
  .sidebar .user-info p {
    display: none; /* Hide brand name and user info when collapsed */
  }
}
@media (max-width: 576px) {
  .login-box {
    max-width: 90%;
  }
}
/* Center the loader container on the screen */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  background-color: rgba(255, 255, 255, 0.9); /* Optional overlay color */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999; /* Ensure it sits above other content */
}

/* Spin animation 
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/
/* Apply the spin animation to the brand logo */
.loader-image {
  width: 100px; /* Adjust size as needed */
  height: 100px;
  animation: spin 2s linear infinite;
}